<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <strong>Master Suite</strong>
      <!-- <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      /> -->
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'TheSidebar',
  data() {
    return {
      nav: []
    }
  },
  mounted() {
    let menu = [
      {
        _name: 'CSidebarNav',
        _children: []
      }
    ]

    let title_menu_principal =
    {
      _name: 'CSidebarNavTitle',
      _children: ['Menú principal']
    }

    // let button_cargue_archivos =
    // {
    //   _name: 'CSidebarNavItem',
    //   name: 'Cargue de Archivos',
    //   to: '/layouts',
    //   icon: 'cil-file'
    // }

    let button_cargue_archivos =
    {
      _name: 'CSidebarNavDropdown',
      name: 'Cargue de Archivos',
      route: '/cargue-archivos',
      icon: 'cil-file',
      items: []
    }

      let button_layouts =
      {
        name: 'Layouts',
        to: '/cargue-archivos/layouts'
      }

      let button_variables =
      {
        name: 'Variables',
        to: '/cargue-archivos/variables'
      }

      let button_relaciones =
      {
        name: 'Relaciones',
        to: '/cargue-archivos/relaciones'
      }

      let button_consolidacion_archivos =
      {
        name: 'Consolidacion Archivos',
        to: '/cargue-archivos/consolidado-archivos'
      }

    let button_catalogo_digital =
    {
      _name: 'CSidebarNavItem',
      name: 'Catálogo Digital',
      icon: 'cil-layers',
      to: '/catalogo-digital/consulta'
    }
    
    let button_calidad =
    {
      _name: 'CSidebarNavDropdown',
      name: 'Calidad',
      route: '/calidad',
      icon: 'cil-task',
      items: []
    }

      let button_reglas =
      {
        name: 'Reglas',
        to: '/calidad/reglas'
      }

      let button_procesos_calidad =
      {
        name: 'Procesos de Calidad',
        to: '/calidad/procesos-calidad'
      }


    let button_conciliador =
    {
      _name: 'CSidebarNavDropdown',
      name: 'Conciliador',
      route: '/conciliador',
      icon: 'cil-task',
      items: []
    }

      let button_reglas_conciliador =
      {
        name: 'Reglas Conciliador',
        to: '/conciliador/reglas'
      }

      let button_procesos_conciliador =
      {
        name: 'Procesos Conciliador',
        to: '/conciliador/procesos-conciliador'
      }


    let button_autogestor =
    {
      _name: 'CSidebarNavDropdown',
      name: 'Autogestor',
      route: '/autogestor',
      icon: 'cil-magnifying-glass',
      items: []
    }

      let button_gestor_consultas =
      {
        name: 'Gestor de Consultas',
        to: '/autogestor/gestor-consultas'
      }
      let button_generar_consulta =
      {
        name: 'Consultas',
        to: '/autogestor/categorias'
      }

    
    let button_repositorio_fotografico =
    {
      _name: 'CSidebarNavDropdown',
      name: 'Repositorio Fotográfico',
      route: '/repositorio-fotografico',
      icon: 'cil-puzzle',
      items: []
    }

      let button_consulta =
      {
        name: 'Consulta',
        to: '/repositorio-fotografico/consulta'
      }

      let button_vitrina =
      {
        name: 'Vitrina Activos Fijos',
        to: '/repositorio-fotografico/vitrina'
      }

      let button_cargar_registros =
      {
        name: 'Cargar Registros',
        to: '/repositorio-fotografico/cargas'
      }

      let button_trasladar_registros =
      {
        name: 'Trasladar Registros',
        to: '/repositorio-fotografico/trasladar'
      }


    let title_componentes_externos =
    {
      _name: 'CSidebarNavTitle',
      _children: ['Componentes Externos']
    }

    let button_reportes =
    {
      _name: 'CSidebarNavDropdown',
      name: 'Reportes',
      route: '/reportes',
      icon: 'cil-chart-pie',
      items: []
    }

      let button_COMDAC =
      {
        name: 'COMDAC',
        to: '/reportes/comdac'
      }

      let button_desincorporacion =
      {
        name: 'Desincorporación',
        to: '/reportes/desincorporacion'
      }

      let button_taxonomia_activos_fijos =
      {
        name: 'Taxonomía de activos fijos',
        to: '/reportes/taxonomia-activos-fijos'
      }

      let button_panoramico_activos_fijos =
      {
        name: 'Panoramico de activos fijos',
        to: '/reportes/panoramico-activos-fijos'
      }

      let button_panorama_validacion_inventarios_op_asociadas =
      {
        name: 'Dashboard Panorama Gestión Validacion de Inventarios Op. Asociadas',
        to: '/reportes/panorama-validacion-inventarios-op-asociadas'
      }

      let button_atencion_solicitudes_2021 =
      {
        name: 'Atención de solicitudes 2021',
        to: '/reportes/atencion-solicitudes-2021'
      }

      let button_gestor_consolidado_solicitudes =
      {
        name: 'Informe Gestor Consolidado de Solicitudes',
        to: '/reportes/gestor-consolidado-solicitudes'
      }

      let button_diagnostico_calidad =
      {
        name: 'Diagnóstico de Calidad',
        to: '/reportes/diagnostico-calidad'
      }

      let button_incorporaciones =
      {
        name: 'Incorporaciones',
        to: '/reportes/incorporaciones'
      }

      let button_reporte_vitrina =
      {
        name: 'Reporte Vitrina',
        to: '/reportes/vitrina'
      }

    
    menu[0]._children.push(title_menu_principal)
	  
    console.log(this.permissions)
    
    if (this.permissions.includes('Definición de variables') || 
        this.permissions.includes('Definición de layouts') || 
        this.permissions.includes('Consulta Relaciones') || this.permissions.includes('Edicion Relaciones')|| 
        this.permissions.includes('Consulta Consolidacion Archivos') || this.permissions.includes('Edicion Consolidacion Archivos'))
      menu[0]._children.push(button_cargue_archivos)

      if (this.permissions.includes('Definición de layouts'))
        button_cargue_archivos.items.push(button_layouts)
      if (this.permissions.includes('Definición de variables'))
        button_cargue_archivos.items.push(button_variables)

      if (this.permissions.includes('Consulta Relaciones') || this.permissions.includes('Edicion Relaciones'))
        button_cargue_archivos.items.push(button_relaciones)

        if (this.permissions.includes('Consulta Consolidacion Archivos') || this.permissions.includes('Edicion Consolidacion Archivos'))
        button_cargue_archivos.items.push(button_consolidacion_archivos)

    // menu[0]._children.push(button_catalogo_digital)

    if (this.permissions.includes('Definición de reglas de calidad') ||
      this.permissions.includes('Definición de proceso de calidad') ||
      this.permissions.includes('Ejecución de proceso de calidad') ||
      this.permissions.includes('Consultar log de ejecuciones')
    )
      menu[0]._children.push(button_calidad)

      if (this.permissions.includes('Definición de reglas de calidad'))
        button_calidad.items.push(button_reglas)

      if (this.permissions.includes('Definición de proceso de calidad'))
        button_calidad.items.push(button_procesos_calidad)

    if (this.permissions.includes('Definición de reglas de conciliador') ||
      this.permissions.includes('Definición de proceso de conciliador') ||
      this.permissions.includes('Ejecución de proceso de conciliador')
    )
      menu[0]._children.push(button_conciliador)

      if (this.permissions.includes('Definición de reglas de conciliador'))
        button_conciliador.items.push(button_reglas_conciliador)

      if (this.permissions.includes('Definición de proceso de conciliador'))
        button_conciliador.items.push(button_procesos_conciliador)

    // Autogestor
    if (this.permissions.includes('Autogestor - Definición de consulta') ||
      this.permissions.includes('Autogestor - Consulta')
    )
      menu[0]._children.push(button_autogestor)

      if (this.permissions.includes('Autogestor - Definición de consulta'))
        button_autogestor.items.push(button_gestor_consultas)
      if (this.permissions.includes('Autogestor - Consulta'))
        button_autogestor.items.push(button_generar_consulta)

    // Repositorio Fotográfico
    if (this.permissions.includes('Repositorio Fotográfico - Consultar') ||
      this.permissions.includes('Repositorio Fotográfico - Cargar') ||
      this.permissions.includes('Repositorio Fotográfico - Vitrina') ||
      this.permissions.includes('Repositorio Fotográfico - Trasladar') || false
    )
      menu[0]._children.push(button_repositorio_fotografico)

      if (this.permissions.includes('Repositorio Fotográfico - Consultar') || false)
        button_repositorio_fotografico.items.push(button_consulta)

      if (this.permissions.includes('Repositorio Fotográfico - Vitrina') || false)
        button_repositorio_fotografico.items.push(button_vitrina)

      if (this.permissions.includes('Repositorio Fotográfico - Cargar') || false)
        button_repositorio_fotografico.items.push(button_cargar_registros)

      if (this.permissions.includes('Repositorio Fotográfico - Trasladar') || false)
        button_repositorio_fotografico.items.push(button_trasladar_registros)

    // Componentes externos
    menu[0]._children.push(title_componentes_externos)
    //if (this.permissions.includes('COMDAC') || this.permissions.includes('Desincorporación'))
    if(button_reportes.items)
      menu[0]._children.push(button_reportes)

      if (this.permissions.includes('COMDAC'))
        button_reportes.items.push(button_COMDAC)
      if (this.permissions.includes('Incorporaciones'))
        button_reportes.items.push(button_incorporaciones)
      if (this.permissions.includes('Desincorporación'))
        button_reportes.items.push(button_desincorporacion)
      if (this.permissions.includes('Taxonomía de activos fijos'))
        button_reportes.items.push(button_taxonomia_activos_fijos)
      if (this.permissions.includes('Panoramico de activos fijos'))
        button_reportes.items.push(button_panoramico_activos_fijos)
      if (this.permissions.includes('Panorama gestión validacion de inventarios op. asociadas'))
        button_reportes.items.push(button_panorama_validacion_inventarios_op_asociadas)
      if (this.permissions.includes('Atención de solicitudes 2021'))
        button_reportes.items.push(button_atencion_solicitudes_2021)
      if (this.permissions.includes('Informe gestor consolidado de solicitudes'))
        button_reportes.items.push(button_gestor_consolidado_solicitudes)
      if (this.permissions.includes('Diagnóstico de calidad'))
        button_reportes.items.push(button_diagnostico_calidad)
      if (this.permissions.includes('Reporte Vitrina'))
        button_reportes.items.push(button_reporte_vitrina)

    this.nav = menu

    console.log(this.nav)
  },
  computed: {
    ...mapState(['permissions']),
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
